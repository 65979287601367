<template>
  <div class="iss-main-grid" ref="mainRef">
    <a-spin :spinning="spinning" size="large">
      <div class="iss-search-wrap">
        <search-form :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
        <grid
          ref="gridRef"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 1200, y: gridHeight }"
        >
          <!--        <template #remake="{ record }">-->
          <!--          {{-->
          <!--            record.remake == null || record.remake == '' ? '-' : record.remake-->
          <!--          }}-->
          <!--        </template>-->
          <template #operation="{ record }">
            <operation :options="getOptions(record)" :record="record" />
          </template>
        </grid>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { message, Spin } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import operation from '@/components/operation';
import cluescoreApi from '@/api/cluescore';
import { reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import * as icons from '@ant-design/icons-vue';

export default {
  components: {
    ASpin: Spin,
    SearchForm,
    Grid,
    operation,
  },
  setup() {
    const state = reactive({
      search: {},
      spinning: false,
      visible: false,
      addActiveItem: {},
      addvisible: false,
      knowledge: null,
      manageListItem: '',
      activeItem: {},
      id: '',
      data: [],
      value: '',
    });
    const router = useRouter();
    const gridRef = ref();
    const mainRef = ref();
    const handleFnSearch = value => {
      state.search = value;
    };
    const replaceFields = {
      children: 'child',
      title: 'name',
    };
    const handleClickAdd = () => {
      state.addvisible = true;
    };
    const watch = data => {
      router.push({
        name: 'cluescoreDetail',
        params: { id: data.id, status: 'watch' },
      });
    };
    const edit = data => {
      if (data.id) {
        router.push({
          name: 'cluescoreDetail',
          params: { id: data.id, status: 'edit' },
        });
      } else {
        router.push({
          name: 'cluescoreDetail',
          params: { id: 'add', status: 'add' },
        });
      }
    };
    const deletes = data => {
      cluescoreApi.delete('', { ids: [data.id] }).then(() => {
        message.success('操作成功');
        gridRef.value.refreshGrid();
      });
    };
    const options = [
      {
        type: 'check',
        icon: 'EyeTwoTone',
        permission: 'cluemanage:check',
        label: '查看',
        fnClick: watch,
      },
      {
        type: 'update',
        permission: 'cluemanage:update',
        label: '编辑',
        fnClick: edit,
      },
      {
        type: 'delete',
        permission: 'cluemanage:delete',
        label: '删除',
        fnClick: deletes,
      },
    ];

    return {
      ...toRefs(state),
      router,
      handleClickAdd,
      gridRef,
      mainRef,
      icons,
      replaceFields,
      handleFnSearch,
      url: cluescoreApi.pageUrl,
      gridHeight: document.body.clientHeight - 359,
      items: [
        { key: 'name', label: '模型名称' },
        // {
        //   key: 'source',
        //   label: '应用线索池名称',
        //   type: 'select',
        //   dataset: [{ label: '默认', value: '默认' }],
        // },
      ],
      columns: [
        { dataIndex: 'name', title: '模型名称', width: 200, ellipsis: true },
        // { dataIndex: 'type', title: '应用线索池名称' },
        // {
        //   dataIndex: 'remake',
        //   title: '备注',
        //   width: 150,
        //   slots: { customRender: 'remake' },
        // },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 180,
          ellipsis: true,
        },
        { dataIndex: 'userName', title: '创建人' },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 124,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'cluemanage:add',
          fnClick: edit,
        },
      ],
      getOptions: item =>
        item.name === '内置评分模板'
          ? options.filter(i => i.type === 'check')
          : options,
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main-grid {
//  max-height: calc(100vh - 120px);
//  overflow-y: auto;
//}
.main-tree {
  padding: 15px;
  border-radius: 8px;
  box-shadow: @box-shadow-base;
  //height: calc(100vh - 209px);
  //overflow-y: auto;
}

.iss-tree {
  height: calc(100vh - 326px);
  overflow-y: auto;
  .iss-tree-item {
    display: flex;
    align-items: center;
    // 超出部分自动换行
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
  }
}
</style>
